import { type Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    display: 'none',
  },
  imageContainerSquare: {
    width: 150,
    height: 150,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      maxWidth: 150,
      maxHeight: 150,
      borderRadius: 4,
      border: '1px solid black',
    },
  },
  imageContainerHorizontal: {
    width: 300,
    height: 150,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      maxWidth: 300,
      maxHeight: 150,
      borderRadius: 4,
      border: '1px solid black',
    },
  },
  uploadRoot: {
    height: '100%',
    marginTop: 8,
  },
  uploadIcon: {
    color: theme.palette.secondary[500],
  },
  removingTitle: {
    cursor: 'pointer',
  },
  failed: {
    border: '2px solid #FA0808 !important',
  },
}))

export default useStyles
