import type { ButtonProps } from '@mui/material'
import { Box, Button, Grid, Tab, Tabs, Typography } from '@mui/material'
import { isSpecifiedDateLessThanCurrent } from 'api/utils/Dates'
import { ReturnButton } from 'common/components/Buttons/ReturnButton'
import { StatusLabel, TabPanel } from 'common/components/CampaignComponents'
import { NotFoundPage } from 'common/components/ErrorBoundaryInside'
import { ButtonWithDialog } from 'common/components/Modals/ButtonWithDialog'
import { historyPushWithLocale } from 'core/history'
import { Routes } from 'core/router/routes'
import {
  campaignSelector,
  campaignStatusSelector,
  campaignUpdateStatus,
  getCampaign,
} from 'core/store/reducers/campaign'
import { clientReset, clientSelector, getClientByCampaignId } from 'core/store/reducers/clientReducer'
import { setSnackbar } from 'core/store/reducers/snackbar'
import type { CampaignStatus } from 'core/type'
import { AdAttachmentType, Statuses, TCampaignType, TransitionStatuses } from 'core/type'
import { CampaignManagementAds } from 'pages/Campaigns/legacy/view/tabs/ads'
import { CampaignManagementAttributes } from 'pages/Campaigns/legacy/view/tabs/attributes'
import { CampaignManagementTargeting } from 'pages/Campaigns/legacy/view/tabs/targeting'
import React, { type SyntheticEvent, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { batch, useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'

import { CampaignAPI } from '../../../../api/campaigns/campaign'
import { SubscriptionService } from '../../../../api/subscription/subscription'
import { ContentContainer } from '../../../../common/components/AppWrapper/ContentContainer'
import { NavigationContainer } from '../../../../common/components/AppWrapper/NavigationContainer'
import Chip from '../../../../common/components/Chip'
import type { AddedImage, IImage } from '../../../../common/components/Images/types'
import { IMAGE_GROUP, IMAGE_TYPE } from '../../../../common/components/Images/types'
import { AbsoluteLoader } from '../../../../common/components/Loaders/AbsoluteLoader'
import { getCurrentLocalePath } from '../../../../core/router/hooks/useLocales'
import { isTenantCompanySelectedSelector, loggedUserSelector } from '../../../../core/store/reducers/auth'
import { Roles } from '../../../../core/store/reducers/userReducer'
import Note from '../../common/Components/Note'
import { StatusDescription } from '../../common/Components/StatusDescription'
import { useCampaignTitle } from '../../common/hooks/useCampaignTitle'
import { filterByGroup, filterByType } from '../../new/steps/advertising/native/schema'
import { TabPreview } from '../../new/steps/preview'
import { failedStatusDetails } from '../common/CampaignFailDetails'
import { useTabLabel } from '../common/hooks/useTabLabel'
import { CampaignManagementOptimization } from './optimization/optimization'

type ButtonColor = ButtonProps['color']

const tabsClass = {
  minWidth: '160px',
  flexDirection: 'row',
  justifyContent: 'center',
  padding: '6px 12px',
  '& svg': {
    marginRight: '8px',
  },
  '&.Mui-selected': {
    color: 'primary',
  },
}

const CampaignManagement: React.FC = () => {
  useCampaignTitle()
  const [tabName, setTabName] = useState('attributeTab')
  const [subscriptionInfo, setSubscriptionInfo] = useState<any>()
  const { id }: { id: string } = useParams()
  const dispatch = useDispatch()
  const campaign = useSelector(campaignSelector)
  const loggedUser = useSelector(loggedUserSelector)
  const campaignStatus = useSelector(campaignStatusSelector)
  const campaignClient = useSelector(clientSelector)
  const tabIcon = useTabLabel(campaign.statusDetails, tabName)
  const { t } = useTranslation()
  const errorDetails = useMemo(() => failedStatusDetails(campaign.statusDetails), [campaign.statusDetails])
  const isTenantCompanySelected = useSelector(isTenantCompanySelectedSelector)
  const isBanner = campaign.type === TCampaignType.banner
  const isSuperadmin = loggedUser.role === Roles.superadmin

  useEffect(() => {
    if (campaign.client) {
      SubscriptionService.getInfo(campaign.client, campaign.id)
        .then((subsInfo) => setSubscriptionInfo(subsInfo as any))
        .catch((e) => console.error(e))
    }
  }, [campaign.client, campaign.id, id])

  const images = campaign.images

  const canNativeBeSubmitted =
    filterByType(filterByGroup(images as Array<IImage | AddedImage>, IMAGE_GROUP.IMAGE), IMAGE_TYPE.SQUARE).length >=
      1 &&
    filterByType(filterByGroup(images as Array<IImage | AddedImage>, IMAGE_GROUP.IMAGE), IMAGE_TYPE.HORIZONTAL)
      .length >= 1

  const canDisplayBeSubmitted =
    campaign.ad.subtype === AdAttachmentType.AMPHTML ? campaign.files.length > 0 : campaign.images.length > 0

  const canBeSubmitted = campaign?.type === TCampaignType.native ? canNativeBeSubmitted : canDisplayBeSubmitted

  useEffect(() => {
    batch(() => {
      dispatch(getCampaign(id))
      dispatch(getClientByCampaignId(id))
    })

    return () => {
      batch(() => {
        dispatch(clientReset())
      })
    }
    // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, [id])

  const handleTabsChange = (_: SyntheticEvent<Element, Event>, value: any) => setTabName(value)

  const DeleteButton = (color?: ButtonColor) => (
    <Grid item>
      <ButtonWithDialog
        header={t('subscriptions.modals.delete.header')}
        disabled={!isTenantCompanySelected}
        cancelButtonText={t('subscriptions.buttons.cancel')}
        onProceed={() => CampaignAPI.delete(campaign.id)}
        proceedButtonStyles={{
          background: '#DC2626',
        }}
        proceedButtonText={t('subscriptions.buttons.delete')}
        actionButton={
          <Button {...(color ? { color } : {})} variant='outlined' disabled={!isTenantCompanySelected}>
            {t('campaigns.buttonNames.delete')}
          </Button>
        }
        body={
          <>
            <Typography variant='subtitle2'>
              {t('subscriptions.modals.delete.campaignDeleteWarning1')}{' '}
              {t('subscriptions.modals.delete.campaignDeleteWarning2')}
              <Typography component='span' display='inline' variant='subtitle1'>
                {id}?
              </Typography>{' '}
              <br />
              {t('subscriptions.modals.delete.campaignDeleteWarning3')}
              <br />
              {subscriptionInfo?.nextCampaign &&
                subscriptionInfo?.previousCampaign && ( // when prev and next campaign exist
                  <>
                    {t('subscriptions.modals.delete.nextCampaign')}
                    <Link
                      target={'_blank'}
                      to={`${getCurrentLocalePath()}/campaigns/view/${subscriptionInfo.nextCampaign.id}`}
                      style={{ textDecoration: 'inherit', display: 'inline-block' }}
                    >
                      <Typography color={'primary'}>{subscriptionInfo.nextCampaign.name}</Typography>
                    </Link>{' '}
                    {t('subscriptions.modals.delete.willBeLinkedTo')}{' '}
                    <Link
                      target={'_blank'}
                      to={`${getCurrentLocalePath()}/campaigns/view/${subscriptionInfo.nextCampaign.id}`}
                      style={{ textDecoration: 'inherit', display: 'inline-block' }}
                    >
                      <Typography color={'primary'}>{subscriptionInfo.previousCampaign.name}</Typography>
                    </Link>{' '}
                    {t('subscriptions.modals.delete.to')}
                  </>
                )}
            </Typography>
          </>
        }
      />
    </Grid>
  )

  const CancelButton = (color?: ButtonColor) => (
    <Grid item>
      <ButtonWithDialog
        disabled={!isTenantCompanySelected}
        windowStyles={{ minWidth: 500 }}
        header={'Kampagne abbrechen?'}
        cancelButtonText={'Abbrechen'}
        proceedButtonStyles={{
          background: '#DC2626',
        }}
        onProceed={async () => {
          const status = await CampaignAPI.setStatus(campaign.id, TransitionStatuses.cancel)
          dispatch(campaignUpdateStatus(status))
        }}
        proceedButtonText={'Kampagne abbrechen'}
        actionButton={
          <Button {...(color ? { color } : {})} variant='outlined' disabled={!isTenantCompanySelected}>
            {t('campaigns.buttonNames.cancel')}
          </Button>
        }
        body={
          <>
            {t('campaigns.modals.delete.body.areYouSure')}{' '}
            <Typography component='span' display='inline' variant='subtitle1'>
              {id}
            </Typography>{' '}
            {t('campaigns.modals.delete.body.toCancel')} <br />
            {t('campaigns.modals.delete.body.thisCannotBeReverted')}
          </>
        }
      />
    </Grid>
  )

  const CopyButton = ({
    color,
    variant = 'contained',
  }: {
    color?: ButtonColor
    variant?: 'text' | 'outlined' | 'contained'
  }) => (
    <Grid item>
      <Button
        variant={variant}
        {...(color ? { color } : {})}
        onClick={() => historyPushWithLocale(`/campaigns/copy/${campaign.id}`)}
      >
        {t('campaigns.buttonNames.copy')}
      </Button>
    </Grid>
  )

  const isPublishDisabled =
    (!campaign.images.length && !campaign.files.length) ||
    !campaign.ad.url ||
    !campaign.locations.length ||
    !canBeSubmitted ||
    isSpecifiedDateLessThanCurrent(campaign.startDate) ||
    !isTenantCompanySelected

  const PublishButton = () => (
    <Grid item>
      <ButtonWithDialog
        windowStyles={{ minWidth: 500 }}
        header={`${t('campaigns.modals.publish.header')}`}
        onProceed={async () => {
          try {
            const newStatus = await CampaignAPI.setStatus(campaign.id, TransitionStatuses.submit)

            dispatch(campaignUpdateStatus(newStatus))
          } catch (error) {
            dispatch(setSnackbar(true, 'error', 'Fehler beim Aktualisieren'))
            // eslint-disable-next-line no-console
            console.log(error)
          }
        }}
        proceedButtonText={`${t('campaigns.modals.publish.proceedButton')}`}
        disabled={isPublishDisabled}
        actionButton={
          <Button variant='contained' disabled={isPublishDisabled}>
            {t('campaigns.buttonNames.publish')}
          </Button>
        }
        body={
          <Typography variant='subtitle2'>
            {t('campaigns.modals.saveAndPublish.body')} <br />
          </Typography>
        }
      />
    </Grid>
  )

  const EditButton = ({
    color,
    variant = 'contained',
  }: {
    color?: ButtonColor
    variant?: 'text' | 'outlined' | 'contained'
  }) => (
    <Grid item>
      <Button
        variant={variant}
        disabled={!isTenantCompanySelected}
        {...(color ? { color } : {})}
        onClick={() => historyPushWithLocale(`/campaigns/edit/${campaign.id}`)}
      >
        {t('campaigns.buttonNames.edit')}
      </Button>
    </Grid>
  )

  const buildButtonsPanel = (statusCampaign: CampaignStatus) => {
    switch (statusCampaign) {
      case 'draft': {
        return (
          <>
            {DeleteButton('inherit')}
            {isTenantCompanySelected && CopyButton({ variant: 'outlined', color: 'inherit' })}
            {EditButton({ variant: 'outlined', color: 'inherit' })}
            {PublishButton()}
          </>
        )
      }

      case 'ready': {
        return (
          <>
            {CancelButton('inherit')} {isTenantCompanySelected && CopyButton({ variant: 'outlined', color: 'inherit' })}{' '}
            {EditButton({ color: 'primary' })}
          </>
        )
      }
      case 'active': {
        return (
          <>
            {CancelButton('inherit')} {isTenantCompanySelected && CopyButton({ variant: 'outlined', color: 'inherit' })}{' '}
            {EditButton({ color: 'primary' })}
          </>
        )
      }
      case 'inReview':
      case 'failed': {
        return (
          <>
            {CancelButton('inherit')} {isTenantCompanySelected && CopyButton({ variant: 'outlined', color: 'inherit' })}{' '}
            {EditButton({ color: 'primary' })}
          </>
        )
      }
      case 'completed': {
        return <>{isTenantCompanySelected && CopyButton({ color: 'primary' })}</>
      }
      case 'canceled': {
        return <>{isTenantCompanySelected && CopyButton({ color: 'primary' })}</>
      }
    }
  }

  return (
    <>
      {Statuses.SUCCESS === campaignStatus ? (
        <>
          <NavigationContainer containerProps={{ pt: 6 }}>
            <Grid container justifyContent='space-between'>
              <Grid item xs={3}>
                <ReturnButton text={t('campaigns.buttonNames.backToList')} link={Routes.CAMPAIGNS} />
              </Grid>
              <Grid item xs={9}>
                <Grid container alignItems='center' justifyContent='flex-end' spacing={4}>
                  {buildButtonsPanel(campaign.status as CampaignStatus)}
                </Grid>
              </Grid>
            </Grid>
            <Box display={'flex'} alignItems={'center'}>
              <Typography variant='h4' color='initial'>
                {campaign.id}
              </Typography>
              {campaign.type && (
                <Box ml={2}>
                  <Chip label={t(`campaigns.types.${campaign.type}`)} />
                </Box>
              )}
              <Box ml={2} />
              <StatusLabel status={campaign.status as CampaignStatus} />
            </Box>
            <Grid container>
              <Box mt={2} component='div'>
                {StatusDescription(campaign.status as CampaignStatus, errorDetails)}
              </Box>
              <Note campaignId={campaign.id} />
            </Grid>
            <Box mt={4}>
              <Tabs
                value={tabName}
                onChange={handleTabsChange}
                indicatorColor='primary'
                textColor='primary'
                aria-label='full width tabs example'
              >
                <Tab
                  sx={tabsClass}
                  value={'attributeTab'}
                  {...tabIcon('attributeTab', t('campaigns.campaignsAttributeTab'))}
                />
                {isBanner && isSuperadmin && (
                  <Tab
                    sx={tabsClass}
                    value={'optimizationTab'}
                    {...tabIcon('optimizationTab', t('campaigns.optimization'))}
                  />
                )}
                <Tab sx={tabsClass} value={'targetingTab'} {...tabIcon('targetingTab', t('campaigns.targetingTag'))} />
                <Tab sx={tabsClass} value={'adTab'} {...tabIcon('adTab', t('campaigns.adTab'))} />
                <Tab sx={tabsClass} value={'previewTab'} {...tabIcon('previewTab', t('campaigns.preview.preview'))} />
              </Tabs>
            </Box>
          </NavigationContainer>
          <ContentContainer>
            <Box>
              <TabPanel value={tabName} name={'attributeTab'} pr='10%'>
                <CampaignManagementAttributes {...campaign} client={campaignClient.name || campaign.client} />
              </TabPanel>
              {isBanner && isSuperadmin && (
                <TabPanel value={tabName} name={'optimizationTab'} pr='10%'>
                  <CampaignManagementOptimization {...campaign} />
                </TabPanel>
              )}
              <TabPanel value={tabName} name={'targetingTab'}>
                <CampaignManagementTargeting campaign={campaign} errorDetails={errorDetails} />
              </TabPanel>
              <TabPanel value={tabName} name={'adTab'} pr='10%'>
                <CampaignManagementAds errorDetails={errorDetails} campaign={campaign} />
              </TabPanel>
              {tabName === 'previewTab' && (
                <TabPanel value={tabName} name={'previewTab'} pr='10%'>
                  {TabPreview({
                    ...campaign,
                    ad: {
                      ...campaign.ad,
                      description: campaign.ad.description || '',
                      headline: campaign.ad.headline || '',
                    },
                  })}
                </TabPanel>
              )}
            </Box>
          </ContentContainer>
        </>
      ) : (
        <AbsoluteLoader />
      )}
      {Statuses.ERROR === campaignStatus && <NotFoundPage />}
    </>
  )
}

export default CampaignManagement
