import { FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material'
import translator from 'i18next'
import React from 'react'
import { useSelector } from 'react-redux'
import type * as Yup from 'yup'

import { AgeCheckboxes } from '../../../../../common/components/AgeCheckboxes'
import { campaignSelector } from '../../../../../core/store/reducers/campaign'
import type { TCampaignType } from '../../../../../core/type'
import { Genders } from '../../../../../core/type'
import { DynamicStep } from '../../../common/Components/FormGenerator/FormBuilder'

const disabledTextColor = {
  color: 'rgba(0, 0, 0, 0.38)',
}

export const TargetingView = (campaignType: TCampaignType | null) => {
  const campaign = useSelector(campaignSelector)

  return DynamicStep({
    label: translator.t('campaigns.adSettings'),
    dependencies: [campaign.type],
    fieldsOrder: [],
    fieldsAdditionalProps: {},
    additionalValidation: {
      gender: (genderValidations) =>
        (genderValidations as Yup.StringSchema).oneOf([Genders.men, Genders.women, Genders.all]).default(Genders.all),
    },
    schemaRequestUrl: `schemas/Target${
      campaignType ? `/${campaignType}` : ''
    }?adServers=all&type=output&operationType=item`,
    additionalQueryParams: {
      subtype: campaign.adAttachmentType,
    },
    initialValues: {
      genders: campaign.gender,
      ageRanges: campaign.ageRanges,
    },
    fieldsToOmit: ['type', 'subtype', 'campaign', 'name'],
    translationsKey: '',
    declareRequired: ['genders', 'ageRanges'],
    renderFunction: (props) => (formik) => {
      return (
        <>
          {props.some((prop) => prop.name === 'genders') && (
            <Grid item xs={6}>
              <Typography variant='subtitle1'>{translator.t('common.gender.gender')}</Typography>
              <RadioGroup
                row
                name='genders'
                aria-label='genders'
                value={formik.values.genders}
                onChange={formik.handleChange}
              >
                <FormControlLabel
                  disabled={true}
                  value={Genders.all}
                  control={<Radio disabled={true} checked={formik.values.genders === Genders.all} color='primary' />}
                  label={
                    <Typography sx={disabledTextColor} variant='body1'>
                      {translator.t('common.gender.all')}
                    </Typography>
                  }
                />
                <FormControlLabel
                  value={Genders.men}
                  control={<Radio disabled={true} checked={formik.values.genders === Genders.men} color='primary' />}
                  label={
                    <Typography sx={disabledTextColor} variant='body1'>
                      {translator.t('common.gender.men')}
                    </Typography>
                  }
                />
                <FormControlLabel
                  value={Genders.women}
                  control={<Radio disabled={true} checked={formik.values.genders === Genders.women} color='primary' />}
                  label={
                    <Typography sx={disabledTextColor} variant='body1'>
                      {translator.t('common.gender.women')}
                    </Typography>
                  }
                />
              </RadioGroup>
            </Grid>
          )}

          {props.some((prop) => prop.name === 'ageRanges') && (
            <Grid item xs={6}>
              <Typography variant='subtitle1'>{translator.t('common.age.age')}</Typography>
              <AgeCheckboxes formik={formik} disabled={true} />
            </Grid>
          )}
        </>
      )
    },
    onSubmit: () => async () => true,
  })
}
