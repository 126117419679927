import { CircularProgress, Grid } from '@mui/material'
import type { FC } from 'react'

export const FullscreenLoader: FC<{ from: string }> = ({ from }) => {
  return (
    <Grid container justifyContent='center' style={{ height: '100vh' }} alignItems='center'>
      <CircularProgress data-testid='loader' data-loaderfrom={from} disableShrink thickness={5} />
    </Grid>
  )
}
