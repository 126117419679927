import { Box, Grid } from '@mui/material'
import { isPrivateRoute } from 'core/router/routes'
import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { isOwnTenantSelectedSelector } from '../../../core/store/reducers/auth'
import { PrivateRoutesFooter } from '../Footers/PrivateRoutesFooter'
import Header from '../Header'
import TenantNotification from './TenantNotification'

const AppContent: React.FC = ({ children }) => {
  const location = useLocation()
  const isOwnTenant = useSelector(isOwnTenantSelectedSelector)

  return (
    <>
      {isPrivateRoute(location.pathname) ? (
        <Box display='flex' flexDirection={'column'} minHeight={'100vh'}>
          <Header />
          {!isOwnTenant && <TenantNotification />}
          <Box
            flexGrow='1'
            display={'flex'}
            justifyContent={'center'}
            width={'100%'}
            overflow={'hidden'}
            paddingBottom={'32px'}
          >
            <Grid container justifyContent={'center'}>
              <Grid item xs={12} style={{ maxWidth: 1200 }}>
                {children}
              </Grid>
            </Grid>
          </Box>
          <PrivateRoutesFooter />
        </Box>
      ) : (
        children
      )}
    </>
  )
}

export default AppContent
