import { Grid, Typography } from '@mui/material'
import type { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import translator from 'i18next'
import React from 'react'

const useStyles = makeStyles((theme: Theme) => ({
  root: { height: '100vh', background: theme.palette.secondary[200] },
  statusCode: {
    fontSize: 300,
    fontWeight: 900,
    color: theme.palette.common.white,
  },
  textColor: {
    color: theme.palette.common.white,
  },
}))

const NotFound: React.FC = () => {
  const classes = useStyles()

  return (
    <Grid className={classes.root} container justifyContent='center' alignItems='center' direction='column'>
      <Typography className={classes.statusCode} variant='h1'>
        {translator.t('notFound.404')}
      </Typography>
      <Typography className={classes.textColor} variant='h4' gutterBottom>
        {translator.t('notFound.siteWasNotFound')}
      </Typography>
      <Typography className={classes.textColor} variant='body1'>
        {translator.t('notFound.pageDoesNotExist')}
      </Typography>
    </Grid>
  )
}

export default NotFound
