import { makeStyles } from '@mui/styles'

export const useStylesNote = makeStyles(() => ({
  container: {
    wordWrap: 'break-word',
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: 32,
    border: 'rgba(0, 0, 0, 0.14) 1px solid',
    borderRadius: '4px',
    padding: '5px 12px',
    marginTop: 16,
    color: 'rgba(0, 0, 0, 0.6)',
    backgroundColor: '#f5f5f5',
  },
  contentWrapper: {
    display: 'flex',
    width: 1085,
  },
  text: {
    marginLeft: 4,
    fontSize: 12,
    fontWeight: 400,
  },
  editButton: {
    fontSize: 12,
    fontWeight: 600,
    textAlign: 'right',
    textDecoration: 'underline',
  },
  addBtnWrapper: {
    cursor: 'pointer',
  },
  addBtnText: {
    fontSize: 12,
    fontWeight: 600,
    color: 'rgba(0, 0, 0, 0.6)',
    textDecoration: 'underline',
    marginBottom: 4,
  },
  addIcon: {
    margin: '7px 5px 0 0',
  },
  nameIcon: {
    margin: '3px 5px 0 0',
  },
  icon: {
    width: 14,
    height: 14,
  },
  nameBlock: {
    fontSize: 12,
    fontWeight: 700,
    userSelect: 'none',
    display: 'flex',
    marginLeft: 5,
  },
  dialogWindow: {
    width: 672,
    minHeight: 258,
    maxHeight: 402,
    backgroundColor: '#fff',
    borderRadius: 8,
    padding: '16px 24px',
  },
  titleWrapper: {
    justifyContent: 'space-between',
  },
  closeButton: {
    width: 24,
    height: 24,
    color: 'rgba(0, 0, 0, 0.6)',
    cursor: 'pointer',
    zIndex: 2000,
  },
  buttons: {
    marginTop: 16,
    justifyContent: 'flex-end',
  },
  title: {
    userSelect: 'none',
    fontSize: 34,
    marginBottom: 24,
  },
}))
