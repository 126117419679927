import { useLoadScript } from '@react-google-maps/api'

const useGoogleMaps = () => {
  useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string, // Add your API key
    libraries: ['places', 'geometry'], // Load required libraries
  })
}

export default useGoogleMaps
