import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd'
import { Icon, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { t } from 'i18next'
import type { FC } from 'react'
import React from 'react'

interface AddLocationsInBulkProps {
  handleModalOpen: (isOpen: boolean) => void
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    marginTop: '16px',
    color: theme.palette.primary.main,
    fontSize: '14px',
    cursor: 'pointer',
    alignItems: 'center',
    '& .MuiTypography-root': {
      marginLeft: '4px',
      fontSize: '14px',
      fontWeight: 600,
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    },
  },
}))

export const AddLocationsInBulk: FC<AddLocationsInBulkProps> = ({ handleModalOpen }: any) => {
  const classes = useStyles()
  const handleDialogOpen = () => {
    handleModalOpen(true)
  }

  return (
    <div className={classes.root} onClick={handleDialogOpen}>
      <Icon component={PlaylistAddIcon}></Icon>
      <Typography>{t('campaigns.addLocationsInBulk')}</Typography>
    </div>
  )
}

export default AddLocationsInBulk
