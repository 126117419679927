import { Box, Link, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import type { FC, ReactNode } from 'react'
import React, { useMemo } from 'react'

const useStyles = makeStyles(() => {
  return {
    root: {
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '24px',
      letterSpacing: '0.5px',
      textDecoration: 'underline',
    },
  }
})
const useErrorStyles = makeStyles(() => {
  return {
    linkError: {
      color: '#FA0808 !important',
    },
  }
})

interface Props {
  label: ReactNode
  body: ReactNode
  href?: boolean
  error?: boolean
  name: string
}

export const ReadonlyField: FC<Props> = ({ body, name, label, href, error }) => {
  const classes = useStyles()
  const { linkError } = useErrorStyles()

  const STANDARD_PROTOCOLS = ['http://', 'https://']

  const sanitizedBodyText = useMemo(() => {
    if (href && STANDARD_PROTOCOLS.includes(body as string)) {
      return ''
    } else {
      return body
    }
    // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, [href, body])

  return (
    <Box maxWidth={588}>
      <Typography style={{ fontSize: 14, wordBreak: 'break-word' }} variant='caption'>
        {label}
      </Typography>
      <Typography data-testid={name} style={{ fontSize: 16, marginTop: 4, wordBreak: 'break-word' }} variant='body2'>
        {href ? (
          <Link
            className={error ? linkError : ''}
            style={{}}
            target={'_blank'}
            color={'primary'}
            classes={classes}
            href={body as string}
          >
            {sanitizedBodyText}
          </Link>
        ) : (
          body
        )}
      </Typography>
    </Box>
  )
}
