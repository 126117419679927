import type { Theme } from '@mui/material/styles'
import { adaptV4Theme, createTheme } from '@mui/material/styles'
import type { PaletteColor as IPaletteColor } from '@mui/material/styles/createPalette'
import 'assets/fonts/SourceSansPro-Regular.ttf'
import Color from 'color'

const sourceSansPro = {
  fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
  src: `
    url(./assets/fonts/SourceSansPro-Regular.ttf) format('embedded-opentype'),
    url(./assets/fonts/SourceSansPro-Regular.ttf) format('woff'),
    url(./assets/fonts/SourceSansPro-Regular.ttf) format('truetype');
    url(./assets/fonts/SourceSansPro-Regular.ttf) format('woff2')
  `,
}

declare module '@mui/styles' {
  interface DefaultTheme extends Theme {}
}

declare module '@mui/material/styles/createPalette' {
  interface PaletteColor {
    '50': string
    '100': string
    '200': string
    '300': string
    '400': string
    '500': string
    '600': string
    '700': string
    '800': string
    '900': string
  }
}

export enum ThemeNames {
  indigo = 'indigo',
  blue = 'blue',
  teal = 'teal',
  brown = 'brown',
  green = 'green',
  custom = 'custom',
}

const DEFAULT_SECONDARY = {
  main: '#E7E8EA',
  50: '#E7E8EA',
  100: '#C3C6CA',
  200: '#9CA0A7',
  300: '#747A84',
  400: '#565E69',
  500: '#38414F',
  600: '#323B48',
  700: '#2B323F',
  800: '#242A36',
  900: '#171C26',
}

const BLUE_THEME = {
  main: '#4696EC',
  50: '#e9f2fd',
  100: '#c8e0f9',
  200: '#a3cbf6',
  300: '#7eb6f2',
  400: '#62a6ef',
  500: '#4696ec',
  600: '#3f8eea',
  700: '#3783e7',
  800: '#2f79e4',
  900: '#2068df',
}

const INDIGO_THEME = {
  main: '#4154AF',
  50: '#e8eaf5',
  100: '#c6cce7',
  200: '#a0aad7',
  300: '#7a87c7',
  400: '#5e6ebb',
  500: '#4154af',
  600: '#3b4da8',
  700: '#32439f',
  800: '#2a3a96',
  900: '#1c2986',
}

const BROWN_THEME = {
  main: '#74564A',
  50: '#eeebe9',
  100: '#d5ccc9',
  200: '#baaba5',
  300: '#9e8980',
  400: '#896f65',
  500: '#74564a',
  600: '#6c4f43',
  700: '#61453a',
  800: '#573c32',
  900: '#442b22',
}

const TEAL_THEME = {
  main: '#419388',
  50: '#e8f2f1',
  100: '#c6dfdb',
  200: '#a0c9c4',
  300: '#7ab3ac',
  400: '#5ea39a',
  500: '#419388',
  600: '#3b8b80',
  700: '#328075',
  800: '#2a766b',
  900: '#1c6458',
}

const GREEN_THEME = {
  main: '#149600',
  50: '#E7F7E5',
  100: '#D1F2CC',
  200: '#A3E499',
  300: '#75D766',
  400: '#47C933',
  500: '#19BC00',
  600: '#149600',
  700: '#0F7100',
  800: '#0A4B00',
  900: '#052600',
}

const GREEN_SECONDARY = {
  main: '#3D4741',
  50: '#DEE0DF',
  100: '#D8DAD9',
  200: '#B1B5B3',
  300: '#8B918D',
  400: '#646C67',
  500: '#3D4741',
  600: '#313934',
  700: '#252B27',
  800: '#181C1A',
  900: '#0C0E0D',
}

interface ThemesInfo {
  primary: Omit<IPaletteColor, 'light' | 'dark' | 'contrastText'>
  secondary: Omit<IPaletteColor, 'light' | 'dark' | 'contrastText'>
}

const createCustomTheme = (color: string): ThemesInfo => {
  const baseColor = Color(color)

  // 400 - 50: (x; y; z(i-1)+z(i-1)*0,1;)
  // 500: (x; y; z;)
  // 600 - 700: (x; y(i-1)-y(i-1)*0,05; z(i-1)-z(i-1)*0,15;)
  // 800 - 900: (x; y(i-1)-y(i-1)*0,2; z(i-1)-z(i-1)*0,25;)

  const primary = {
    main: color,
    50: baseColor
      .hsl()
      .darken(Math.pow(1 + 0.1, 5) - 1)
      .toString(),
    100: baseColor
      .hsl()
      .darken(Math.pow(1 + 0.1, 4) - 1)
      .toString(),
    200: baseColor
      .hsl()
      .lighten(Math.pow(1 + 0.1, 3) - 1)
      .toString(),
    300: baseColor
      .hsl()
      .darken(Math.pow(1 + 0.1, 2) - 1)
      .toString(),
    400: baseColor
      .hsl()
      .darken(Math.pow(1 + 0.1, 1) - 1)
      .toString(),
    500: baseColor.hsl().toString(),
    600: baseColor
      .hsl()
      .lighten(Math.pow(1 + 0.15, 1) - 1)
      .saturate(Math.pow(1 + 0.05, 1) - 1)
      .toString(),
    700: baseColor
      .hsl()
      .lighten(Math.pow(1 + 0.15, 2) - 1)
      .saturate(Math.pow(1 + 0.05, 2) - 1)
      .toString(),
    800: baseColor
      .hsl()
      .lighten(Math.pow(Math.pow(1 + 0.15, 2) * 0.25, 1) - 1)
      .saturate(Math.pow(Math.pow(1 + 0.05, 2) * 0.2, 1) - 1)
      .toString(),
    900: baseColor
      .hsl()
      .lighten(Math.pow(Math.pow(1 + 0.15, 2) * 0.25, 2) - 1)
      .saturate(Math.pow(Math.pow(1 + 0.05, 2) * 0.2, 2) - 1)
      .toString(),
  }

  const theme = createTheme(
    adaptV4Theme({
      palette: {
        primary,
      },
    })
  )

  return {
    primary: theme.palette.primary,

    secondary: DEFAULT_SECONDARY,
  }
}

export const findOrCreateTheme = (type?: ThemeNames, baseColor?: string): ThemesInfo => {
  switch (type) {
    case ThemeNames.blue: {
      return {
        primary: BLUE_THEME,
        secondary: DEFAULT_SECONDARY,
      }
    }
    case ThemeNames.brown: {
      return {
        primary: BROWN_THEME,
        secondary: DEFAULT_SECONDARY,
      }
    }
    case ThemeNames.indigo: {
      return {
        primary: INDIGO_THEME,
        secondary: DEFAULT_SECONDARY,
      }
    }

    case ThemeNames.teal: {
      return {
        primary: TEAL_THEME,
        secondary: DEFAULT_SECONDARY,
      }
    }

    case ThemeNames.green: {
      return {
        primary: GREEN_THEME,
        secondary: GREEN_SECONDARY,
      }
    }

    case ThemeNames.custom: {
      if (!baseColor) {
        // eslint-disable-next-line no-console
        console.error('Theme type provided as custom, but no base color provided')

        return {
          primary: GREEN_THEME,
          secondary: GREEN_SECONDARY,
        }
      }

      return createCustomTheme(baseColor)
    }

    default:
      return {
        primary: GREEN_THEME,
        secondary: GREEN_SECONDARY,
      }
  }
}

export const getTheme = (type?: ThemeNames, baseColor?: string): Theme => {
  const { secondary, primary } = findOrCreateTheme(type, baseColor)

  return createTheme(
    adaptV4Theme({
      spacing: (factor: number) => `${0.25 * factor}rem`,
      palette: {
        background: {
          default: '#fafafa',
        },
        text: {
          // disabled: `rgb(0, 0, 0)`
        },
        primary,
        secondary,
        error: {
          main: '#D02D41',
        },
        warning: {
          main: '#FA8B08', // 02. On Surface/Warnings & Errors
        },
        success: {
          main: '#0B8D00', // 02. On Surface / Success
          100: 'rgba(0, 0, 0, 0.08)', // 01. Surface/Overlay/Medium
          200: 'rgba(0, 0, 0, 0.6)', // 02. On Surface / Medium Emphasis
          300: 'rgb(255,255,255)', // 01. Surface/Overlay/Medium
          400: '#F2F2F2', // Surface/Light Gray
        },
      },
      typography: {
        fontFamily: 'Source Sans Pro, sans-serif',
        fontSize: 14,
        fontWeightLight: 400,
        fontWeightRegular: 500,
        fontWeightMedium: 600,
        fontWeightBold: 700,
        h5: {
          fontWeight: 600,
          lineHeight: '1.75rem',
        },
        h4: {
          fontSize: '28px',
          fontWeight: 400,
          lineHeight: '34px',
          letterSpacing: '0.25px',
        },
        body1: {
          fontSize: '0.875rem',
        },
        body2: {
          fontSize: '0.75rem',
        },
        caption: {
          color: 'rgba(0, 0, 0, 0.6)',
        },
        subtitle1: {
          fontSize: '1rem',
          fontWeight: 600,
          lineHeight: '1.5rem',
          letterSpacing: '0.15px',
        },
        subtitle2: {
          fontWeight: 500,
        },
      },
      overrides: {
        MuiButton: {
          root: {
            height: '40px',
            textTransform: 'uppercase',
          },
          outlined: {
            border: '1px solid rgba(0, 0, 0, 0.12)',
          },
        },
        MuiInputLabel: {
          outlined: {
            lineHeight: '18px !important',
          },
          asterisk: {
            color: '#B00020',
          },
        },
        MuiCssBaseline: {
          '@global': {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            '@font-face': [sourceSansPro],
          },
        },
      },
    })
  )
}
