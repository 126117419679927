import { Typography } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import type { FC } from 'react'

import { getCurrentLocalePath } from '../../../../../core/router/hooks/useLocales'
import { Routes } from '../../../../../core/router/routes'
import { FaqLink } from '../../../common/Components/FaqLink'

const useErrorMessageStyles = makeStyles(() =>
  createStyles({
    root: {
      color: '#FA0808',
      fontSize: 12,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '14px',
      letterSpacing: '0.4px',
    },
  })
)

interface Props {
  message: string
  FAQHref?: string | number
}

export const AdServerErrorMessage: FC<Props> = ({ message, FAQHref }) => {
  const classes = useErrorMessageStyles()

  return (
    <Typography component={'p'} classes={classes}>
      {message}
      {FAQHref && <FaqLink href={`${getCurrentLocalePath()}${Routes.HELP}?section=${FAQHref.toString()}`} />}
    </Typography>
  )
}
