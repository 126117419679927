import { StyledEngineProvider, type Theme, ThemeProvider } from '@mui/material/styles'
import { getTheme } from 'core/palette'
import { appearanceSelector } from 'core/store/reducers/appearance'
import React from 'react'
import { useSelector } from 'react-redux'

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

interface ThemeProviderWrapperProps {
  children: React.ReactNode
}

const ThemeProviderWrapper: React.FC<ThemeProviderWrapperProps> = ({ children }) => {
  const { theme, baseColor } = useSelector(appearanceSelector)
  const customizedTheme = getTheme(theme, baseColor)

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={customizedTheme}>{children}</ThemeProvider>
    </StyledEngineProvider>
  )
}

export default ThemeProviderWrapper
