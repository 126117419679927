import { Box } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import type { FC } from 'react'
import React from 'react'
// @ts-ignore
import { MarvelDevices } from 'react-css-devices'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '& img': {
        display: 'block',
        width: '100%',
        filter:
          'drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.14)) ' +
          'drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.12)) ' +
          'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2));',
        borderRadius: 4,
      },
    },
  })
)

export const Desktop: FC = ({ children }) => {
  const { root } = useStyles()

  return (
    <MarvelDevices deviceName={'macbook'} color={'black'} orientation={'portrait'} transform={0.6}>
      <Box className={root}>{children}</Box>
    </MarvelDevices>
  )
}
