import Alert from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setSnackbar } from '../../../core/store/reducers/snackbar'

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
})

const Snackbars: React.FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  // @ts-ignore
  const snackbarOpen = useSelector((state) => state.snackbar.snackbarOpen)
  // @ts-ignore
  const snackbarType = useSelector((state) => state.snackbar.snackbarType)
  // @ts-ignore
  const snackbarMessage = useSelector((state) => state.snackbar.snackbarMessage)
  const handleClose = (event: any, reason: any) => {
    if (reason === 'clickaway') {
      return
    }
    dispatch(setSnackbar(false, snackbarType, snackbarMessage))
  }

  return (
    <div className={classes.root}>
      <Snackbar open={snackbarOpen} autoHideDuration={10000} onClose={handleClose}>
        <Alert
          elevation={6}
          variant='filled'
          // @ts-ignore
          onClose={handleClose}
          color={snackbarType}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  )
}

export default Snackbars
