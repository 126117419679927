import { Box, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { getOwnTenantIdSelector } from '../../../core/store/reducers/auth'
import {
  isTenantsLoadingSelector,
  selectedTenantSelector,
  tenantsSelector,
} from '../../../core/store/reducers/dropdownTenants'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '36px',
    width: '100%',
    background: 'rgba(0, 0, 0, 0.6)',
  },
  infoMessage: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    color: 'rgba(242, 242, 242, 1)',
    '& > a': {
      color: '#F2F2F2',
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '26px',
      textDecoration: 'underline',
    },
  },
}))

const TenantNotification = () => {
  const ownTenantId = useSelector(getOwnTenantIdSelector)
  const classes = useStyles()
  const selectedTenant = useSelector(selectedTenantSelector)
  const tenants = useSelector(tenantsSelector)
  const isTenantsLoading = useSelector(isTenantsLoadingSelector)
  const { t } = useTranslation()

  const ownTenant = useMemo(
    () =>
      tenants.find((tenant) => {
        return tenant.id === +ownTenantId
      }),
    [ownTenantId, tenants]
  )

  const tenant = useMemo(
    () =>
      tenants.find((filteringTenant) => {
        return filteringTenant.id === selectedTenant?.id
      }),
    [selectedTenant?.id, tenants]
  )

  if (!isTenantsLoading && ownTenant) {
    return (
      <Box className={classes.root}>
        <Typography className={classes.infoMessage}>
          {t('tenantNotifications.otherTenantSelected', { ownTenantName: ownTenant?.name, tenantName: tenant?.name })}
          {<Link target={'_blank'} to={`//${tenant?.domain}`}>{`${tenant?.name}`}</Link>}
        </Typography>
      </Box>
    )
  } else {
    return null
  }
}

export default TenantNotification
