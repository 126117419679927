import { makeStyles } from '@mui/styles'
import type { FC } from 'react'
import { Link } from 'react-router-dom'

const useStyles = makeStyles(() => {
  return {
    link: {
      color: 'rgba(0, 0, 0, 0.60)',
      fontSize: 12,
      marginLeft: 4,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '14px',
      letterSpacing: '0.4px',
      textDecorationLine: 'underline',
    },
  }
})

interface Props {
  href: string
}

export const FaqLink: FC<Props> = ({ href }) => {
  const { link } = useStyles()

  return (
    <Link target={'_blank'} className={link} to={href}>
      Learn more
    </Link>
  )
}
