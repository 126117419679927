import { Box, Grid, Typography } from '@mui/material'
import { formatCurrency } from 'common/components/Formatters/currency'
import { getCurrentLocaleConfig, getCurrentLocalePath } from 'core/router/hooks/useLocales'
import type { EnhancedCampaign } from 'core/type'
import { format } from 'date-fns'
import { flatten } from 'lodash'
import React, { lazy } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { CategoryHeader } from '../../../../../common/components/Typography/CategoryHeader'
import { ReadonlyField } from '../../../../../common/components/Typography/ReadonlyField'
import { LazyLoading } from '../../../../../core/router/utils/LazyLoading'
import { calcTimeInHours } from '../../../common/utils/formatDays'
import { getDailyBudget, getDaysDiff } from '../../../new/steps/campaign/schema'

const CampaignPerformance = lazy(() => import('../common/performance/CampaignPerformance'))

export const isTimeManuallySet = (startDate: string, endDate: string) => {
  if (startDate && endDate) {
    return !(/00:00:00/.test(startDate) && /23:59:59/.test(endDate))
  }

  return false
}

export const CampaignManagementAttributes: React.FC<EnhancedCampaign> = (campaignData) => {
  const { t } = useTranslation()

  const clientCampaigns = flatten(campaignData.packages.map(({ campaigns }) => campaigns))
  const isPartOfSubscription = clientCampaigns.some((campaign) => campaign.id === campaignData.id)

  const formatDate = `${format(
    new Date(campaignData.startDate as string),
    getCurrentLocaleConfig().fullSlashDateFormat
  )} - 
       ${format(new Date(campaignData.endDate as string), getCurrentLocaleConfig().fullSlashDateFormat)}`

  const hoursCount = calcTimeInHours(campaignData.startDate as string, campaignData.endDate as string)
  const daysCount = `${getDaysDiff(campaignData.startDate, campaignData.endDate) || '0'} ${t(
    'campaigns.fields.days'
  )} (${hoursCount} ${t('campaigns.fields.hours')})`

  return (
    <>
      {LazyLoading(CampaignPerformance)}
      <CategoryHeader value={t('campaigns.generalAttributes')} />
      <Grid container spacing={6}>
        <Grid item xs={6}>
          <ReadonlyField name={'campaign-name'} label={t('campaigns.campaignName')} body={campaignData.name} />
          <Box mb={3} />
          <ReadonlyField name={'client'} label={t('campaigns.fields.client')} body={campaignData.client} />
          <Box mb={3} />
        </Grid>
        <Grid item xs={6}>
          <ReadonlyField name={'campaign-id'} label={t('campaigns.campaignId')} body={campaignData.id} />
        </Grid>
      </Grid>

      <Box mb={3} />

      <Grid container spacing={6}>
        <Grid item xs={6}>
          <Typography variant='h6'>{t('subscriptions.subscription')}</Typography>

          {isPartOfSubscription && (
            <>
              <Grid container wrap='nowrap' alignItems={'center'}>
                <Grid item xs zeroMinWidth>
                  <Typography style={{ fontSize: 16 }}>
                    {t('subscriptions.view.campaignIsPartOfSubscription')}
                  </Typography>
                </Grid>
              </Grid>

              <Box mb={2}></Box>

              <Typography variant={'caption'}>{t('subscriptions.view.previousCampaign')}</Typography>
              <Box>
                {campaignData.previousCampaign.id ? (
                  <Link
                    to={`${getCurrentLocalePath()}/campaigns/view/${campaignData.previousCampaign.id}`}
                    style={{ textDecoration: 'inherit' }}
                  >
                    <Typography color={'primary'}>{campaignData.previousCampaign.name}</Typography>
                  </Link>
                ) : (
                  <Typography color={'primary'}>{t('subscriptions.view.none')}</Typography>
                )}
              </Box>
            </>
          )}

          {!isPartOfSubscription && (
            <Typography style={{ fontSize: 16 }}>{t('subscriptions.view.campaignIsNotAPartOfSubscription')}</Typography>
          )}
        </Grid>
      </Grid>

      <Box mb={7} />
      <CategoryHeader value={t('campaigns.fields.scheduleAndBudget')} />
      <Box mb={3} />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6}>
              <ReadonlyField name={'start-end-date'} label={t('campaigns.fields.startEndDate')} body={formatDate} />
              <Box mb={3} />
            </Grid>
            <Grid item xs={6}>
              <ReadonlyField label={t('campaigns.fields.duration')} name={'total-count'} body={daysCount} />
              <Box mb={3} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6}>
              <ReadonlyField
                name={'total-budget'}
                label={t('campaigns.fields.totalBudget')}
                body={formatCurrency(campaignData.totalBudget)}
              />
            </Grid>
            <Grid item xs={6}>
              <ReadonlyField
                name={'daily-budget'}
                label={t('campaigns.fields.dailyBudget')}
                body={formatCurrency(
                  getDailyBudget(campaignData.startDate, campaignData.endDate, campaignData.totalBudget)
                )}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
