import type { IImage } from 'common/components/Images/types'
import type { ClientI } from 'core/store/reducers/clientReducer'

import type { Package } from '../api/subscription/types'
import type { ILocation } from '../common/components/Maps'
import type { AgeGroups } from './store/reducers/campaign'

export interface CampaignResponse extends Campaign {
  age: Array<AgeGroups>
  ageRanges: Record<AgeGroups, boolean>
  devices: Array<Device>
  locations: Array<ILocation>
  genders: Array<GenderI>
  ads: Array<Ad>
}

export interface ITargetParams {
  ageRanges?: Record<AgeGroups, boolean> | undefined
  genders?: string
  devices?: string
}

export enum TBiddingStrategy {
  CPC = 'CPC (enhanced)',
  CPM = 'Viewable CPM',
  MAX_CLICKS = 'Maximize clicks',
}

export interface Ad {
  name: string
  url?: string
  type: TCampaignType | null
  id?: number | null
  campaign?: number
  headline: string
  subtype: AdAttachmentType
  description: string
  businessName: string
}

export enum AdAttachmentType {
  IMAGE = 'image',
  AMPHTML = 'amphtml',
}

export interface AMPHTML {
  id: number
  url: string
  name: string // part of url
  type: AdAttachmentType.AMPHTML
}

export interface AddedAMPHTML {
  id: null | number
  url: string | null
  file: File
  name: string
  type: AdAttachmentType.AMPHTML
  image?: Blob
}

export enum TCampaignType {
  banner = 'banner',
  native = 'native',
}

export interface EnhancedCampaign extends Campaign {
  ageRanges: Record<AgeGroups, boolean>
  devices: {
    mobile: boolean
    desktop: boolean
  }
  bidStrategy: {
    bidStrategy: TBiddingStrategy | null
    bidValue: number | string
  }
  gender: Genders
  totalBudget: number
  adAttachmentType: AdAttachmentType
  ad: Ad
  images: Array<IImage>
  files: Array<AMPHTML | AddedAMPHTML>
  locations: Array<ILocation>
  redirectedToWizard: boolean
  type: TCampaignType | null
  packages: Array<Package>
  isSubscriptionActive: boolean
  previousCampaign: Campaign
  statusDetails: StatusDetails
  targetImpressions?: number
}

export interface CampaignsResponse {
  campaigns: Array<Campaign & { client: ClientI }>
  totalItems: number
}

export interface StatusDetails {
  status: CampaignStatus
  failureCode?: number
  failureKey?: string
  failureMessage?: string
  failedResource?: string
}

export interface Campaign {
  id: number
  name: string
  startDate: Date | null | string
  endDate: Date | null | string
  status: Statuses | string
  client: string
  tenant: string
  updatedAt: string | Date | null
  createdAt: string | Date | null
  totalBudget: number
}

export enum LocationTypes {
  POINT = 'point',
  ADDRESS = 'address',
}

export enum Devices {
  desktop = 'desktop',
  mobile = 'mobile',
}

export interface Device {
  type: Devices | string
  campaign: number | string
}

export enum Genders {
  men = 'men',
  women = 'women',
  all = 'men,women',
}

export interface GenderI {
  gender: Genders
  campaign: number | string
}

export enum Statuses {
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  NONE = 'NONE',
}

export enum TransitionStatuses {
  submit = 'submit',
  toDraft = 'toDraft',
  pause = 'pause',
  resume = 'resume',
  deactivate = 'deactivate',
  cancel = 'cancel',
}

export enum CampaignStatus {
  draft = 'draft',
  ready = 'ready',
  active = 'active',
  // paused = 'paused',
  canceled = 'canceled',
  completed = 'completed',
  failed = 'failed',
  inReview = 'inReview',
}

export enum AdServers {
  googleAds = 'googleAds',
  seedingAlliance = 'seedingAlliance',
}

export enum Budgets {
  daily = 'daily',
  total = 'total',
}

export type AdServerTypes = keyof typeof AdServers

export type BudgetTypes = keyof typeof Budgets

export interface IResponseSummary {
  impressions: number
  clicks: number
  days: number
  click_rates: number
}

export interface IYesterdayImpressions {
  impressions: number
  clicks: number
  click_rates: number
}

export interface IResponseWebReport extends Performance {
  summary: IResponseSummary
  yesterday: IYesterdayImpressions
}
