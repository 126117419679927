import { Box, Typography } from '@mui/material'
import React, { useEffect } from 'react'
// @ts-ignore
import { useTranslation } from 'react-i18next'

import { Mobile } from '../../devices/Mobile'
import { BlockSimulation } from '../../devices/markupSimulation/block'
import type { AMPHTMLParseResult } from './index'

const CreateMobile = ({ parseResult }: { parseResult?: AMPHTMLParseResult }) => {
  const { t } = useTranslation()
  useEffect(() => {
    const screen = document.querySelector('.screen')

    if (screen) {
      screen.setAttribute('style', 'overflow: hidden;background: white;')
      const screenChild = screen.querySelector('div')

      if (screenChild) {
        screenChild.setAttribute('style', 'position: absolute;width: 100%;')
      }
    }
  })

  return (
    <Mobile>
      <Box pr={1} pl={1} pt={2}>
        <Box p={5}>
          <Box margin={'auto'}>
            {parseResult ? (
              <div
                style={{
                  width: parseResult.width,
                  height: parseResult.height,
                  minHeight: parseResult.height,
                  minWidth: parseResult.width,
                  margin: 'auto',
                }}
              >
                <iframe
                  title={'frame'}
                  style={{
                    border: 'none',
                    display: 'block',
                    height: `${parseResult.height}px`,
                    width: `${parseResult.width}px`,
                    maxWidth: '100%',
                  }}
                  allowFullScreen={true}
                  width={`${parseResult.width}px`}
                  height={`${parseResult.height}px`}
                  srcDoc={parseResult.html}
                />
              </div>
            ) : (
              <Typography variant={'h3'} color='initial' style={{ color: 'black' }}>
                {t('campaigns.preview.previewIsNotAvailable')}
              </Typography>
            )}
          </Box>
          {/* <img alt={'native image preview'} src={imageUrl || NoImage} />*/}
        </Box>
        <Box mt={4} mb={4} ml={2} mr={2}>
          <BlockSimulation height={20} />
        </Box>
        <Box mb={2} ml={2} mr={2}>
          <BlockSimulation />
        </Box>
        <Box mb={4} ml={2} mr={2}>
          <BlockSimulation />
        </Box>

        <Box ml={2} mr={2}>
          <BlockSimulation height={500} />
        </Box>
      </Box>
    </Mobile>
  )
}

export default CreateMobile
