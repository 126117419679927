import { makeStyles } from '@mui/styles'

const styles = makeStyles(() => ({
  root: {
    height: 32,
    background: 'white',
    width: '100%',
    boxShadow: '0px 1px 5px 0px #00000038;',
  },
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    maxWidth: '1200px',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '0 auto',
  },
  dot: {
    width: '3px',
    height: '3px',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    borderRadius: '100%',
    marginLeft: '5px',
  },
  companyName: {
    marginLeft: 5,
  },
  link: {
    '&:link': {
      textDecoration: 'none',
    },
    '&:visited': {
      textDecoration: 'none',
    },
    '&:hover': {
      textDecoration: 'none',
    },
    '&:active': {
      textDecoration: 'none',
    },
  },
}))

export default styles
