import { Box, Grid, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Desktop } from '../../devices/Desktop'
import { BlockSimulation } from '../../devices/markupSimulation/block'
import type { AMPHTMLParseResult } from './index'

const DESKTOP_WIDTH = 576

const CreateDesktop = ({ parseResult }: { parseResult?: AMPHTMLParseResult }) => {
  const { t } = useTranslation()

  useEffect(() => {
    const screen = document.querySelector('.screen')
    const screenFirstDescendant = document.querySelector('.screen>div')

    if (screen) {
      screen.setAttribute('style', 'overflow: hidden;background: white;')
    }

    if (screenFirstDescendant) {
      screenFirstDescendant.setAttribute('style', 'position: absolute; width: 100%;')
    }
  })

  const ErrorMessage = (
    <Desktop>
      <Box p={12}>
        <Grid container spacing={10} direction={'row'}>
          <Grid item xs={8}>
            <BlockSimulation height={20} />
            <Box mt={5}>
              <BlockSimulation height={20} width={50} />
            </Box>
            <Box mt={5}>
              <BlockSimulation height={20} />
            </Box>
            <Box mt={5}>
              <BlockSimulation height={20} width={80} />
            </Box>
            <Box mt={5}>
              <BlockSimulation height={500} />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box width={'100%'} height={300}>
              <Typography variant={'h3'} color='initial' style={{ color: 'black' }}>
                {t('campaigns.preview.previewIsNotAvailable')}
              </Typography>
            </Box>
            {/* <img alt={'native image preview'} src={imageUrl || NoImage} />*/}
            <Box mt={4} mb={4}>
              <BlockSimulation height={250} />
            </Box>
            <Box mt={4} mb={4}>
              <BlockSimulation height={250} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Desktop>
  )

  const Preview = (
    <Desktop>
      <Box p={12}>
        <Box
          style={{
            position: 'absolute',
            right: 40,
            display: 'block',
            height: `${parseResult?.height}px`,
            width: `${parseResult?.width}px`,
            maxWidth: '100%',
          }}
        >
          {parseResult && (
            <div
              style={{
                width: parseResult.width,
                height: parseResult.height,
                minHeight: parseResult.height,
                minWidth: parseResult.width,
              }}
            >
              <iframe
                title={'frame'}
                id='iframe'
                src={window.location.origin}
                style={{
                  border: 'none',
                  display: 'block',
                  height: `${parseResult.height}px`,
                  width: `${parseResult.width}px`,
                  maxWidth: '100%',
                }}
                allowFullScreen={true}
                width={`${parseResult.width}px`}
                height={`${parseResult.height}px`}
                srcDoc={parseResult.html}
              />
            </div>
          )}
          <Box mt={4} />
          <BlockSimulation height={20} />
          <Box mt={5}>
            <BlockSimulation height={20} width={'30%'} />
          </Box>
          <Box mt={5}>
            <BlockSimulation height={20} />
          </Box>
          <Box mt={5}>
            <BlockSimulation height={20} width={'40%'} />
          </Box>
          <Box mt={5}>
            <BlockSimulation height={500} />
          </Box>
        </Box>
        <Box
          width={DESKTOP_WIDTH - (parseResult?.width || 250) * 0.4}
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
        >
          <Grid container direction={'row'}>
            <Grid item xs={12}>
              <BlockSimulation height={20} />
              <Box mt={5}>
                <BlockSimulation height={20} width={50} />
              </Box>
              <Box mt={5}>
                <BlockSimulation height={20} />
              </Box>
              <Box mt={5}>
                <BlockSimulation height={20} width={80} />
              </Box>
              <Box mt={5}>
                <BlockSimulation height={500} />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Desktop>
  )

  return <>{parseResult ? Preview : ErrorMessage}</>
}

export default CreateDesktop
