import { Box, Grid } from '@mui/material'
import type { BoxProps } from '@mui/material/Box/Box'
import { makeStyles } from '@mui/styles'
import type { FC } from 'react'

const useStyles = makeStyles(() => {
  return {
    container: {
      position: 'relative',
      '&:before': {
        position: 'absolute',
        top: 0,
        bottom: 0,
        content: '""',
        left: '-100%',
        width: '100%',
        height: '100%',
        background: 'inherit',
      },
      '&:after': {
        position: 'absolute',
        top: 0,
        bottom: 0,
        content: '""',
        right: '-100%',
        width: '100%',
        height: '100%',
        background: 'inherit',
      },
    },
  }
})

export const ContentContainer: FC<{ bgColor?: string; containerProps?: BoxProps }> = ({
  children,
  bgColor = 'inherit',
  containerProps = { pt: 6, pb: 6 },
}) => {
  const { container } = useStyles()

  return (
    <Box className={container} style={{ background: bgColor }}>
      <Grid container>
        <Grid item xs={12}>
          <Box {...containerProps}>{children}</Box>
        </Grid>
      </Grid>
    </Box>
  )
}
