import { ClearRounded, SearchRounded } from '@mui/icons-material'
import { IconButton, InputAdornment, MenuItem, TextField } from '@mui/material'
import { MAXIMUM_LOCATIONS_QUANTITY } from 'pages/Campaigns/legacy/edit/schema'
import type { FC } from 'react'
import { createRef, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { Suggestion } from 'react-places-autocomplete'
import PlacesAutocomplete from 'react-places-autocomplete'
import { useSelector } from 'react-redux'

import { campaignSelector } from '../../../../core/store/reducers/campaign'
import { makeHelperTextClasses, makeInputLabelStyles, makeInputStyles } from '../../Inputs/FormikMaterialInput'
import { CountriesListUtil } from './countriesListUtil'

interface ILocationSearchInput {
  addLocation: (addressToAdd: string, suggestion: Suggestion) => void
  setTouched?: (value: boolean) => void
  isPoint: boolean
  disabled?: boolean
  initialValue?: string
  focusInitially?: boolean
  error: boolean
}

export const LocationSearchInput: FC<ILocationSearchInput> = ({
  addLocation,
  setTouched,
  disabled = false,
  initialValue = '',
  focusInitially,
  error,
}) => {
  const { t } = useTranslation()
  const inputRef = createRef<HTMLInputElement>()
  const inputLabelStyles = makeInputLabelStyles()
  const inputClassesStyles = makeInputStyles()
  const helperTextClasses = makeHelperTextClasses()

  const { type } = useSelector(campaignSelector)

  useEffect(() => {
    if (focusInitially && inputRef.current) {
      inputRef.current.focus()
    }
    // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, [])

  const [address, setAddress] = useState(initialValue)

  const handleChange = (changedAddress: string) => {
    setAddress(changedAddress)
  }

  const onFocus = () => {
    setTouched && setTouched(false)
  }

  const onBlur = () => {
    setTouched && setTouched(true)
  }

  const handleSelect = async (selectedAddress: string, placeId?: string, suggestion?: Suggestion) => {
    try {
      if (suggestion && placeId) {
        addLocation(selectedAddress, suggestion)
        setAddress('')
      }
    } catch (selectError) {
      console.error('Error', selectError)
    }
  }

  return (
    <PlacesAutocomplete
      debounce={process.env.NODE_ENV === 'test' ? 0 : 400}
      searchOptions={{
        componentRestrictions: {
          country: CountriesListUtil(type!) as any /* typings here are outdated */,
        },
      }}
      value={address}
      onChange={handleChange}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          <TextField
            error={error}
            required
            {...getInputProps({
              placeholder: t('campaigns.searchPlaces'),
              className: 'location-search-input',
            })}
            InputLabelProps={{
              classes: inputLabelStyles,
            }}
            FormHelperTextProps={{
              classes: helperTextClasses,
            }}
            fullWidth
            id='location'
            name='location'
            type='text'
            label={t('campaigns.address')}
            variant='outlined'
            disabled={disabled}
            onFocus={onFocus}
            onBlur={onBlur}
            helperText={
              disabled && t('campaigns.validation.cannotAddMoreLocations', { locations: MAXIMUM_LOCATIONS_QUANTITY })
            }
            InputProps={{
              classes: inputClassesStyles,
              startAdornment: (
                <InputAdornment position='start'>
                  <SearchRounded fontSize='small' />
                </InputAdornment>
              ),
              endAdornment: address && (
                <IconButton data-testid='remove-point' size='small' onClick={() => setAddress('')}>
                  <ClearRounded fontSize='small' />
                </IconButton>
              ),
              inputRef,
            }}
          />
          <div className='autocomplete-dropdown-container'>
            {loading && <div>Loading...</div>}
            {suggestions.map((suggestion, i) => {
              const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item'
              // inline style for demonstration purpose
              const style = suggestion.active
                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                : { backgroundColor: '#ffffff', cursor: 'pointer' }

              return (
                <MenuItem
                  // @ts-ignore
                  key={i}
                  data-testid={suggestion.description}
                  {...getSuggestionItemProps(suggestion, {
                    className,
                    style,
                  })}
                >
                  {suggestion.description}
                </MenuItem>
              )
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  )
}
