import { Box, Typography } from '@mui/material'
import { t } from 'i18next'
import React from 'react'

import { ReadonlyField } from '../../../../../common/components/Typography/ReadonlyField'
import type { EnhancedCampaign } from '../../../../../core/type'
import { BiddingReadOnlyFieldLabel } from './BiddingReadOnlyFieldLable'

export const CampaignManagementOptimization: React.FC<EnhancedCampaign> = (campaignData) => {
  const { bidStrategy } = campaignData
  const OptionalLabel = BiddingReadOnlyFieldLabel()

  return (
    <>
      <Box mb={4}>
        <Typography variant='h6'>{t('campaigns.googleAdsServerOptimization')}</Typography>
      </Box>
      <Box>
        <ReadonlyField
          name={'bidStrategy.bidStrategy'}
          label={t('campaigns.fields.biddingStrategy')}
          body={t(`tenants.budgets.optimization.${bidStrategy.bidStrategy}`)}
        />
        <Box mb={3} />
        {OptionalLabel}
      </Box>
    </>
  )
}
