import { CircularProgress } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'

const styles = makeStyles({
  root: {
    position: 'absolute',
    zIndex: 9999,
    top: '50%',
    left: '50%',
  },
})

export const AbsoluteLoader = () => {
  const { root } = styles()

  return <CircularProgress disableShrink className={root} />
}
